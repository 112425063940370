<template>
  <div class="w-100">
    <div>
      <RelationsView />
    </div>
  </div>
</template>

<script>
import RelationsView from '@/components/Domain/EntityRelationsView.vue'
import { mapState } from 'vuex'

export default {
  name: 'EntityRelations',
  components: {
    RelationsView,
  },
  computed: {
    ...mapState('domainModel', ['composition_tree', 'selected_entity2']),
  },
  mounted() {
    const curRoute = this.$route.params.id
  },
  methods: {
    // Fully refresh the store
    refreshGraph() {
      this.$store.dispatch('domainModel/getCompTreeData', this.$route.params.id).then(() => {
        console.debug('Refreshed Ontology: ', this.composition_tree)
      })
    },
  },
}
</script>

<style scoped>

</style>
